<template>
  <div class="container">
    <form class="form-reset" ref="form-reset" @submit.prevent="changePassword">
      <div class="form-control">
        <input
          v-model="passwordFirst"
          type="password"
          name="passwordFirst"
          placeholder="Enter password"
        />
      </div>
      <div class="form-control">
        <input
          v-model="passwordSecond"
          type="password"
          name="passwordSecond"
          placeholder="Confirm password"
        />
      </div>
      <div class="form-control">
        <small>* New password must be at least 8 symbols.</small>
      </div>
      <div class="form-reset__actions">
        <button type="submit" class="button button-primary">
          Reset Password
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { showSnackbar } from '@/services'
import { mapMutations } from 'vuex'
import mtApi from '@/agGridV2/helpers/mt-api.helper'

export default {
  name: 'Reset Password',

  data() {
    return {
      passwordFirst: '',
      passwordSecond: '',
      token: this.$route.params.token,
      // email: this.$route.params.email
    }
  },

  methods: {
    ...mapMutations(['setViewLoader']),
    async changePassword() {
      if (this.passwordFirst !== this.passwordSecond) {
        showSnackbar({
          mode: 'error',
          text: 'Password mismatch'
        })
        return false
      }
      this.setViewLoader(true)

      try {
        const response = await mtApi.resetPassword({
          token: this.token,
          password: this.passwordFirst,
          password_confirmation: this.passwordSecond
        })

        showSnackbar({
          mode: 'success',
          text: response.message
        })

        setTimeout(() => {
          this.$router.push({ name: 'login' })
          this.setViewLoader(false)
        }, 1000)
      } finally {
        this.setViewLoader(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-reset {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 600px;
  margin: 0 auto;
  padding: 60px;
  background-color: $bg-secondary;

  &__actions {
    display: flex;
    margin-top: 2rem;
  }
}
.form-control + .form-control {
  margin-top: 1rem;
}
</style>
